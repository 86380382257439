import React from "react";

import { Helmet } from "react-helmet";

import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import AuthNavbar from "components/Navigation/Navbars/Auth/AuthNavbar";

import { dashRoutes as routes } from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/blogStyle.js";
import Footer from "components/Sales/Footer";
import Banner from "components/Sales/Banner";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Stakeholders.app Pricing | Professional, Free</title>
        <meta
          name="description"
          content="Stakeholders.app Pricing | Professional, Free Tier - Stakeholder Mapping and Analysis"
        />
      </Helmet>
      <div className={classes.fullPage}>
        <AuthNavbar {...rest} shrinkOnValue={275} />

        <div className={classes.content}>
          <Banner small />
          <div className={classes.container + " " + classes.padding}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/" to="/" />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
