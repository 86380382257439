import React from "react";

import {
  makeStyles,
  Typography,
  CardContent,
  ButtonBase,
  CardMedia,
  Paper,
} from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { dashRoutes as routes } from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/views/blogStyle.js";

const useStyles = makeStyles(styles);

export default function Home(props) {
  const classes = useStyles();
  const { history } = props;

  const getBlogs = (routes) => {
    return routes.filter((prop, key) => {
      return prop.layout === "/journal" && prop.path !== "/";
    });
  };

  var blogs = getBlogs(routes);

  return (
    <div className={classes.content}>
      <GridContainer justify="center">
        {blogs.map((blog, key) => {
          return (
            <GridItem xs={12} md={6} lg={4} key={key}>
              <Paper elevation={0} square classes={{ root: classes.card }}>
                <ButtonBase
                  className={classes.cardAction}
                  classes={{ root: classes.cardActionRoot }}
                  onClick={() => history.push(blog.layout + blog.path)}
                >
                  <CardContent className={classes.cardContent}>
                    <Typography variant="h5" gutterBottom>
                      {blog.name}
                    </Typography>
                  </CardContent>
                  <CardMedia
                    className={classes.media}
                    image={blog.data.imageSrc}
                    title={blog.name}
                  />
                </ButtonBase>
              </Paper>
            </GridItem>
          );
        })}
        {blogs.length <= 0 && (
          <Typography>Nothing here right now, come back again :-)</Typography>
        )}
      </GridContainer>
    </div>
  );
}
