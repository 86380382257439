import { firestore } from "services/firebase";
import firebase from "firebase/app";
import { isUserPremium } from "./firebase";

const sendToCheckoutSession = async ({ user }) => {
  console.log("sendToCheckoutSession ...");

  const premium = await isUserPremium();
  if (premium) {
    sendToStripeCustomerPortal({ returnPath: "/" });
    return;
  }

  try {
    const docRef = await firestore
      .collection("customers")
      .doc(user.uid)
      .collection("checkout_sessions")
      .add({
        //price: "price_1JPGiNCB2n4uDWOpTbhx0QCn",
        price: "price_1Jaqf2CB2n4uDWOps8NZdBjo",
        success_url: window.location.origin + "/?checkout=success",
        cancel_url: window.location.origin + "/customer/sign-up",
      });

    // Wait for the CheckoutSession to get attached by the extension
    await docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  } catch (e) {
    console.log(e);
  }
};

const sendToStripeCustomerPortal = async ({ returnPath }) => {
  console.log("sendToStripeCustomerPortal ...");
  const functionRef = firebase
    .app()
    .functions("australia-southeast1")
    .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
  const { data } = await functionRef({
    returnUrl: window.location.origin + returnPath,
  });
  window.location.assign(data.url);
};

export { sendToCheckoutSession, sendToStripeCustomerPortal };
