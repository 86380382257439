import {
  atlanticColor,
  chillColor,
} from "assets/jss/material-dashboard-pro-react";

const emailStyle = (theme) => ({
  textField: {
    marginTop: "11px",
    width: "inherit",
  },
  inputRoot: {
    height: "inherit !important",
    color: atlanticColor[0],
    padding: 0,
  },
  submitBtn: {
    background: atlanticColor[0],
    minWidth: "39px",
    height: "36px",
    stroke: chillColor[0],

    "&:hover": {
      stroke: atlanticColor[0],
      color: atlanticColor[0],
      border: `2px solid ${atlanticColor[0]}`,
    },
  },
  circularProgress: {
    color: chillColor[0],
  },
});

export default emailStyle;
