import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function Responsive(props) {
  const { children, mobile, normal } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  if ((normal && !matches) || (mobile && matches)) {
    return children;
  }

  return <></>;
}

const IsMobile = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  return matches;
};

const mobileBounds = () => {
  return window.innerWidth < 600;
};

const IsMedium = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  return matches;
};

export { Responsive, IsMobile, mobileBounds, IsMedium };
