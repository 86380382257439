import { antiBlurText } from "assets/jss/material-dashboard-pro-react.js";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const customAlertStyle = (theme) => ({
  ...buttonStyle,
  antiBlurText,
});

export default customAlertStyle;
