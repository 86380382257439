const maxStakeholderCount = 15;

const PowerInterestGrid = {
  highDetail: {
    ignore: (interest, power) => {
      return interest <= 35 && power <= 35;
    },
    keepInformed: (interest, power) => {
      return interest > 35 && power <= 35;
    },
    keepOnSide: (interest, power) => {
      return power <= 70 && power > 35;
    },
    watch: (interest, power) => {
      return interest <= 35 && power > 75;
    },
    keepSatisfied: (interest, power) => {
      return interest <= 70 && interest > 35 && power > 70;
    },
    consistentActiveManagement: (interest, power) => {
      return interest > 70 && power > 70;
    },
  },

  manageClosely: (interest, power) => {
    return interest > 50 && power > 50;
  },
  keepSatisfied: (interest, power) => {
    return interest <= 50 && power > 50;
  },
  keepInformed: (interest, power) => {
    return interest > 50 && power <= 50;
  },
  monitor: (interest, power) => {
    return interest <= 50 && power <= 50;
  },

  low: (value) => {
    return value < 50;
  },
  high: (value) => {
    return value >= 50;
  },

  filterLow: (value) => {
    return value < 35;
  },
  filterMedium: (value) => {
    return value < 65;
  },
  filterHigh: (value) => {
    return value >= 65;
  },
};

const relationship = (_relationship) => {
  if (_relationship >= 25) {
    return "high";
  }
  if (_relationship >= 0) {
    return "neutral";
  }
  return "low";
};

const isSubset = (limit, value) => {
  return value > limit;
};
const limit = 50;

const SalienceModel = {
  section: (_power, _interest, _legitimacy) => {
    let power = isSubset(limit, _power);
    let urgency = isSubset(limit, _interest);
    let legitimacy = isSubset(limit, _legitimacy);

    if (power && urgency && legitimacy) {
      return "abc";
    } else if (power && legitimacy) {
      return "ab";
    } else if (power && urgency) {
      return "ac";
    } else if (urgency && legitimacy) {
      return "bc";
    } else if (power) {
      return "a";
    } else if (legitimacy) {
      return "b";
    } else if (urgency) {
      return "c";
    }
    return "empty";
  },
};

export { PowerInterestGrid, SalienceModel, relationship, maxStakeholderCount };
