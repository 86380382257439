const accountStyle = (theme) => ({
  container: {
    // Fixes Grid from adding -16px to top
    // Fixes avatar image showing too high
    [theme.breakpoints.down("xs")]: {
      margin: "20px -16px -16px -16px",
    },
  },
  disabledText: {
    color: "slateGrey",
  },
  cardActions: {
    //display: "flex",
    //justifyContent: "center",
  },
});

export default accountStyle;
