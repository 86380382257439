//import { authContainer } from "assets/jss/material-dashboard-pro-react";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/utils/buttonStyle";

const pricingStyle = (theme) => ({
  ...buttonStyle,
  container: {
    width: "100%",
  },
  content: {
    display: "flex",
    justifyContent: "center",
  },
  table: {
    maxWidth: "750px",
  },
  endRow: {
    borderBottom: "none",
  },
  buyLabel: {
    display: "flex",
    flexDirection: "column",
  },
  buyRoot: {
    width: "100%",
  },
});

export default pricingStyle;
