import { v4 as uuidv4 } from "uuid";

const VARIABLES = {
  clientVersion: "2.0",
  template: {
    defaultId: "defaultId",
  },
};

const placeManyStakeholderIdsIntoProjectOrderings = ({
  project,
  stakeholderIds,
}) => {
  project.template.actions.defaultId.ordering.stakeholderIds = [
    ...project.template.actions.defaultId.ordering.stakeholderIds,
    ...stakeholderIds,
  ];

  project.stakeholdersComponent.ordering.stakeholderIds = [
    ...project.stakeholdersComponent.ordering.stakeholderIds,
    ...stakeholderIds,
  ];

  return project;
};

const placeStakeholderIdIntoProjectOrderings = ({ project, stakeholderId }) => {
  project.template.actions.defaultId.ordering.stakeholderIds.push(
    stakeholderId
  );
  project.stakeholdersComponent.ordering.stakeholderIds.push(stakeholderId);
  return project;
};

const createFeedbackDocumentId = () => {
  return "feedback-" + uuidv4();
};

const duplicateProjectData = ({ userId, project, stakeholders }) => {
  let dupProject = createProject({ userId: userId });
  dupProject.name = project.name;
  dupProject.notes = project.notes ? project.notes : "";
  dupProject.goal = project.goal ? project.goal : "";
  dupProject.decisionMaker = project.decisionMaker ? project.decisionMaker : "";

  const stakeholderIds = project.stakeholdersComponent.ordering.stakeholderIds;
  const dupStakeholderIds = new Array(stakeholderIds.length);

  let dupStakeholders = stakeholders.map((s) => {
    const stakeholder = createStakeholder({
      ...s,
    });
    const orderingIndex = stakeholderIds.findIndex((id) => id === s.id);
    dupStakeholderIds[orderingIndex] = stakeholder.id;
    return stakeholder;
  });
  dupProject.stakeholdersComponent.ordering.stakeholderIds = dupStakeholderIds;

  return [dupProject, dupStakeholders];
};

const createStakeholder = ({
  name = "",
  position = "",
  power = 50,
  interest = 50,
  relationship = 0,
  legitimacy = 50,
  notes = "",
  actionPlan = createStakeholderActionPlan({ notes: "" }),
}) => {
  return {
    name: name,
    position: position,
    power: power,
    interest: interest,
    relationship: relationship,
    legitimacy: legitimacy,
    id: uuidv4(),
    actionPlan: actionPlan,
    notes: notes,
  };
};

const createProject = ({ userId, id }) => {
  return {
    name: "",
    notes: "",
    goal: "",
    id: id ? id : uuidv4(),
    created: Date.now(),
    template: createTemplate({ id: "", actions: {} }),
    sharing: false,
    userId: userId,
    stakeholdersComponent: {
      ordering: {
        stakeholderIds: [],
      },
    },
  };
};

const createTemplate = ({ id, actions }) => {
  return {
    id: id,
    actions: {
      [VARIABLES.template.defaultId]: createAction({
        title: "No action allocated yet",
        stakeholderIds: [],
        index: 0,
      }),
      ...actions,
    },
  };
};

const createAction = ({ title, stakeholderIds, index }) => {
  return {
    title: title,
    ordering: {
      stakeholderIds: stakeholderIds,
    },
    index: index,
  };
};

const createStakeholderActionPlan = ({ notes = "" }) => {
  return {
    notes: notes,
  };
};

const createClient = () => {
  return {
    version: VARIABLES.clientVersion,
  };
};

const createUser = ({ uid, name, email, free }) => {
  let paidFeatures = {};

  if (!free) {
    paidFeatures = {
      uid: uid,
      name: name,
      email: email,
    };
  }

  return {
    ...paidFeatures,
    client: VARIABLES.clientVersion,
    projectsComponent: {
      projectLinks: {},
      ordering: {
        projectIds: [],
      },
    },
  };
};

export {
  VARIABLES,
  createStakeholder,
  createProject,
  createTemplate,
  createAction,
  createStakeholderActionPlan,
  createClient,
  createUser,
  duplicateProjectData,
  createFeedbackDocumentId,
  placeStakeholderIdIntoProjectOrderings,
  placeManyStakeholderIdsIntoProjectOrderings,
};
