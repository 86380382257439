import React from "react";

import { CircularProgress, makeStyles, Typography } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import { signOut, signInWithGoogle } from "services/firebase";
import { UserContext } from "services/auth";
import { sendToCheckoutSession } from "services/stripe";

import styles from "assets/jss/material-dashboard-pro-react/views/signUpStyle.js";

const useStyles = makeStyles(styles);

export default function SignUp(props) {
  const history = useHistory();
  const classes = useStyles();
  const { setSnackbar } = props;

  const [user] = React.useContext(UserContext);
  const [loadingCheckout, setLoadingCheckout] = React.useState(false);

  React.useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const message = query.get("message");
    if (message) {
      setSnackbar({
        show: true,
        message:
          "A subscription is required to enter the dashboard with an account.",
      });
    }
  }, [history.location.search, setSnackbar]);

  const handleExit = () => {
    signOut();
    history.push("/");
  };

  const handleLearnMore = () => {
    //signOut();
    history.push("/pricing");
  };

  const handleSubscribe = async () => {
    if (user) {
      setLoadingCheckout(true);
      sendToCheckoutSession({ user: user });
    } else {
      try {
        const response = await signInWithGoogle();

        setLoadingCheckout(true);
        sendToCheckoutSession({ user: response.user });
      } catch (e) {
        console.log(e);
        setLoadingCheckout(false);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Card className={classes.card}>
          <CardContent>
            <Button
              variant="outlined"
              color="secondary"
              classes={{
                label: classes.buyLabel,
                root: classes.buyRoot,
              }}
              onClick={handleSubscribe}
            >
              {loadingCheckout ? (
                <CircularProgress color="secondary" />
              ) : (
                <>
                  <Typography variant="h6">$4 / month</Typography>
                  <small>USD*</small>
                </>
              )}
            </Button>

            <Typography
              variant="caption"
              component="p"
              className={classes.terms}
            >
              By subscribing you agree to the{" "}
              <a href="/legal/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </Typography>
          </CardContent>
          <CardActions classes={{ root: classes.cardActionsRoot }}>
            <Button size="small" onClick={handleLearnMore}>
              Learn More
            </Button>
            <Button color="primary" onClick={handleExit}>
              Exit to free version
            </Button>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}
