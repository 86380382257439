import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Button from "components/CustomButtons/Button";

import logo from "assets/img/logo.png";
import styles from "assets/jss/material-dashboard-pro-react/components/navbar/auth/authHeaderStyle.js";

const useStyles = makeStyles(styles);

export default function Heading(props) {
  const classes = useStyles();
  const { hasScrolledDown, isMobile } = props;

  if (isMobile) {
    return (
      <div className={classes.container}>
        <a href="/" className={classes.logoContainer}>
          <img src={logo} alt="logo" height="40" width="40" />
        </a>
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        <Button href="/" className={classes.title} color="transparent">
          <Typography variant="h1" className={classes.titleTypography}>
            Stakeholders
          </Typography>
        </Button>

        {!hasScrolledDown && (
          <div className={classes.divider}>
            {window.innerWidth >= 600 && (
              <Typography
                variant="caption"
                className={classes.subtitleTypography}
              >
                by Tahi Teams
              </Typography>
            )}
          </div>
        )}
      </div>
    );
  }
}
