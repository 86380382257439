import React from "react";

import cx from "classnames";
import moment from "moment";

import {
  makeStyles,
  Button,
  Grid,
  IconButton,
  CardActions,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// @material-ui/icons
import CloudIcon from "@material-ui/icons/Cloud";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BackupIcon from "@material-ui/icons/Backup";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

import DefaultAvatar from "assets/img/default-avatar.png";
import styles from "assets/jss/material-dashboard-pro-react/views/accountStyle.js";

import {
  getAllProjectsFromLocalStorage,
  getStakeholdersFromLocalStorage,
} from "database/LocalStorage";
import { UserContext } from "services/auth";
import { importLocalProjectIntoFirestore } from "database/Firestore";
import { sendToStripeCustomerPortal } from "services/stripe";
import { deleteUserFromFirestore } from "database/Firestore";
import { signOut } from "services/firebase";

import CustomAlert from "components/Alert/CustomAlert";

const useStyles = makeStyles(styles);

export default function Account(props) {
  const classes = useStyles();
  const { loadedOwnedProjects, setMasterAlert, setSnackbar } = props;
  const [user] = React.useContext(UserContext);

  const [photoURL, setPhotoURL] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [localProjects, setLocalProjects] = React.useState([]);
  const [loadingProjectIds, setLoadingProjectIds] = React.useState([]);

  const [loading, setLoading] = React.useState({
    portal: false,
    deleteUser: false,
  });

  React.useEffect(() => {
    if (user) {
      setPhotoURL(user.photoURL);
      setDisplayName(user.displayName);
      setEmail(user.email);
    } else {
      setPhotoURL(DefaultAvatar);
      setDisplayName("Free account");
      setEmail("");
    }

    getAllProjectsFromLocalStorage({
      observer: (data) => {
        setLocalProjects(data.projects);
      },
    });
  }, [user]);

  const deleteAccountWarning = () => {
    setMasterAlert(
      <CustomAlert
        title="Delete account"
        onConfirm={handleDeleteAccount}
        onCancel={() => setMasterAlert(null)}
        confirmBtnText="Yes, delete my account"
        cancelBtnText="Cancel"
        danger={true}
        showCancel={true}
      >
        Warning: Deleting your account will
        <br />
        remove all of your data and cancel billing.
      </CustomAlert>
    );
  };

  const handleDeleteAccount = async () => {
    setLoading({ ...loading, deleteUser: true });
    const response = await deleteUserFromFirestore();

    setMasterAlert(null);

    if (response?.data === "success") {
      setSnackbar({
        show: true,
        message: "Your account and all associated data has been deleted.",
      });
      signOut();
    } else {
      setSnackbar({
        show: true,
        message: "There was an issue deleting your account.",
      });
    }

    setLoading({ ...loading, deleteUser: false });
  };

  const handleImportIntoCloud = async (project) => {
    if (loadedOwnedProjects.length > 10) {
      return;
    }

    setLoadingProjectIds([...loadingProjectIds, project.id]);

    const stakeholders = getStakeholdersFromLocalStorage({
      projectId: project.id,
    });
    importLocalProjectIntoFirestore({
      userId: user.uid,
      project: project,
      stakeholders: stakeholders,
    });

    setLoadingProjectIds(loadingProjectIds.filter((id) => id === project.id));
  };

  const handlePortal = async () => {
    setLoading({ ...loading, portal: true });
    try {
      await sendToStripeCustomerPortal({ returnPath: "/admin/account" });
    } catch (e) {
      setLoading({ ...loading, portal: false });
    }
  };

  const displayIcon = (project) => {
    if (!user || loadingProjectIds.includes(project.id)) {
      return (
        <IconButton disabled>
          <BackupIcon />
        </IconButton>
      );
    }

    if (loadedOwnedProjects.find((p) => p.id === project.id)) {
      return (
        <IconButton disabled>
          <CloudDoneIcon />
        </IconButton>
      );
    }

    return (
      <IconButton
        disabled={!user}
        onClick={() => handleImportIntoCloud(project)}
      >
        <CloudIcon />
      </IconButton>
    );
  };

  const tablecellClasses = cx({
    [classes.disabledText]: !user,
  });

  return (
    <Grid container item spacing={4} className={classes.container}>
      <Grid item xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="orangePeel" icon>
            <CardIcon color="orangePeel">
              <AccountTreeIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Projects - <small>Import from Local Storage into the cloud</small>
            </h4>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Import</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {localProjects.map((project) => (
                    <TableRow key={project.id}>
                      <TableCell className={tablecellClasses}>
                        {project.name}
                      </TableCell>
                      <TableCell className={tablecellClasses}>
                        {moment(project.created).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell>{displayIcon(project)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!user && (
              <Typography color="error" variant="caption">
                This feature is only available for premium tier.
              </Typography>
            )}
          </CardBody>
        </Card>
      </Grid>

      {user && (
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Card profile>
            <CardAvatar profile>
              <img src={photoURL} alt="" />
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>{email}</h6>
              <h4 className={classes.cardTitle}>{displayName}</h4>
            </CardBody>
            <CardActions classes={{ root: classes.cardActions }}>
              <Button
                variant="outlined"
                color="secondary"
                disabled={!user}
                onClick={deleteAccountWarning}
                style={{ width: "152px" }} // For same width when circular progress
              >
                {loading.deleteUser ? (
                  <CircularProgress color="secondary" size={30} />
                ) : (
                  <>Delete account</>
                )}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )}

      {user && (
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card>
            <CardHeader color="orangePeel" icon>
              <CardIcon color="orangePeel">
                <CreditCardIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Payments and Billing</h4>
            </CardHeader>
            <CardBody>
              <Button
                variant="outlined"
                color="secondary"
                disabled={!user}
                onClick={handlePortal}
                style={{ width: "105px" }} // For same width when circular progress
              >
                {loading.portal ? (
                  <CircularProgress color="secondary" size={30} />
                ) : (
                  <>To portal</>
                )}
              </Button>
            </CardBody>
            <CardActions classes={{ root: classes.cardActions }}></CardActions>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
