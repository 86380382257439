import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { addUserToFirestore } from "database/Firestore";

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PID,
  storageBucket: process.env.REACT_APP_SB,
  messagingSenderId: process.env.REACT_APP_SID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MID,
};

firebase.initializeApp(config);

const auth = firebase.auth();

const firestore = firebase.firestore();

/** To sign up using google
 * Link for developer documentation (https://firebase.google.com/docs/auth/web/google-signin)
 */
const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({
  promt: "select_account",
});

const signInWithGoogle = async () => {
  try {
    let response = await auth.signInWithPopup(provider);

    if (response?.user && response?.additionalUserInfo?.isNewUser)
      addUserToFirestore({ user: response.user });

    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const signOut = () => auth.signOut();

const isUserPremium = async () => {
  await auth.currentUser?.getIdToken(true);
  const decodedToken = await auth.currentUser?.getIdTokenResult();
  const role = decodedToken?.claims?.stripeRole;
  return role === "premium";
};

export { signInWithGoogle, firestore, auth, signOut, isUserPremium };
