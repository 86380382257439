import {
  atlanticColor,
  authContainer,
} from "assets/jss/material-dashboard-pro-react.js";

const customerStyle = (theme) => ({
  wrapper: {
    overflow: "hidden",
  },
  fullPage: {
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
  },
  padding: {
    paddingLeft: authContainer.paddingLg.left,
    paddingRight: authContainer.paddingLg.right,

    [theme.breakpoints.down("md")]: {
      paddingLeft: `${authContainer.paddingMd.left}`,
      paddingRight: `${authContainer.paddingSm.right}`,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: `${authContainer.paddingSm.left}`,
      paddingRight: `${authContainer.paddingSm.right}`,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: `${authContainer.paddingXs.left}`,
      paddingRight: `${authContainer.paddingXs.right}`,
    },
  },
  content: {
    position: "relative",
    zIndex: "3",
    width: "100%!important",
    color: atlanticColor[0],
  },
  container: {
    minHeight: "300px",
    //margin: "20px 0 70px 0",
    margin: "20px 0 50px 0",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      paddingBottom: "70px",
    },
  },
});

export default customerStyle;
