import React from "react";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/sales/utilsStyle";

const useStyles = makeStyles(styles);

const EntryButton = (props) => {
  const classes = useStyles();
  const { title } = props;

  return (
    <Button
      className={classes.buttonLarge + " " + classes.atlantic}
      component={Link}
      to="/admin/project"
    >
      {title}
    </Button>
  );
};

const SignUpButton = () => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonLarge + " " + classes.chill}
      component={Link}
      to="/pricing"
    >
      Sign Up
    </Button>
  );
};

const HomeButton = () => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonLarge + " " + classes.chill}
      component={Link}
      to="/"
    >
      Home
    </Button>
  );
};

const BlogButton = () => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonLarge + " " + classes.chill}
      component={Link}
      to="/blog"
    >
      Blog
    </Button>
  );
};

export { EntryButton, SignUpButton, HomeButton, BlogButton };
