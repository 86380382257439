import buttonStyle from "assets/jss/material-dashboard-pro-react/components/utils/buttonStyle";

const signUpStyle = (theme) => ({
  ...buttonStyle,
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    maxWidth: "750px",
  },
  endRow: {
    borderBottom: "none",
  },
  buyLabel: {
    display: "flex",
    flexDirection: "column",
  },
  buyRoot: {
    width: "100%",
  },
  card: {
    //
  },
  cardActionsRoot: {
    justifyContent: "space-around",
  },
  message: {
    margin: "30px",
  },
  terms: {
    margin: "15px",
  },
});

export default signUpStyle;
