import React from "react";

import cx from "classnames";

// @material-ui/core components
import { makeStyles, Typography } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/components/sales/bannerStyle";

import BannerAuthCard from "./BannerAuthCard";
import { IsMobile, IsMedium } from "components/Responsive/Responsive.js";

const useStyles = makeStyles(styles);

export default function Banner(props) {
  const isMedium = IsMedium();
  const isMobile = IsMobile();
  const classes = useStyles();
  const { small } = props;

  var wrapper =
    classes.wrapper +
    " " +
    cx({
      [classes.wrapperSmall]: small,
      [classes.mobileWrapper]: isMobile,
    });

  const title =
    classes.title +
    " " +
    cx({
      [classes.mobileTitle]: isMobile,
    });

  if (isMedium) {
    return (
      <div className={wrapper}>
        <div className={classes.container}>
          <div>
            <div className={classes.heading}>
              <Typography className={title} variant="h2">
                Manage your project risk
              </Typography>
              <Typography
                className={classes.subtitle}
                style={{ textAlign: "center", textTransform: "none" }}
                variant="h1"
              >
                Stakeholder Mapping and Analysis
              </Typography>
            </div>
          </div>

          <BannerAuthCard />
        </div>
      </div>
    );
  } else {
    return (
      <div className={wrapper}>
        <div className={classes.container}>
          <div className={classes.heading}>
            <Typography className={title} variant="h2">
              Manage your project risk
            </Typography>
            {!small && (
              <Typography className={classes.subtitle} variant="h1">
                Stakeholder Mapping and Analysis
              </Typography>
            )}
          </div>
        </div>

        <BannerAuthCard />
      </div>
    );
  }
}
