import React from "react";

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { rows, value } from "variables/pricing";

import { UserContext } from "services/auth";
import { signInWithGoogle } from "services/firebase";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingStyle.js";
import { sendToCheckoutSession } from "services/stripe";
import { isUserPremium } from "services/firebase";

const useStyles = makeStyles(styles);

export default function Pricing() {
  const classes = useStyles();
  const [user] = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [premium, setPremium] = React.useState(false);

  React.useEffect(() => {
    const loadPremium = async () => {
      const _premium = await isUserPremium();
      setPremium(_premium);
      return _premium;
    };
    loadPremium();
  }, [user]);

  const handleSubscribe = () => {
    if (user) {
      setLoading(true);
      sendToCheckoutSession({ user: user });
    } else {
      signInWithGoogle();
    }
  };

  const loadButton = () => {
    return (
      <Button
        variant="simple"
        color="secondary"
        classes={{
          label: classes.buyLabel,
          root: classes.buyRoot,
        }}
        onClick={handleSubscribe}
        style={{ width: "160px" }} // For same width when circular progress
      >
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            {premium && <Typography variant="caption">Current</Typography>}
            <Typography variant="h6">$4 / month</Typography>
            <small>USD*</small>
          </>
        )}
      </Button>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <TableContainer className={classes.table}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Casual</TableCell>
                <TableCell align="center">Premium</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell align="center">{value(row.proValue)}</TableCell>
                  <TableCell align="center">{value(row.freeValue)}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.endRow}
                ></TableCell>
                <TableCell align="center" className={classes.endRow}>
                  <Typography noWrap>Free always</Typography>
                </TableCell>
                <TableCell align="center" className={classes.endRow}>
                  {loadButton()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
