import {
  atlanticColor,
  authContainer,
} from "assets/jss/material-dashboard-pro-react.js";

const entryStyle = (theme) => ({
  // Page
  simpleEntryContactForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 69vh)",
    minHeight: "300px",
    paddingBottom: "35px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "70px",
      height: "calc(100vh - 41vh)",
    },
  },
  wrapper: {
    position: "relative",
    zIndex: "3",
    width: "100%!important",
    color: atlanticColor[0],
  },
  // Accessibility
  container: {
    height: "496px",
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      height: "auto",
      paddingBottom: "30px",
    },
  },
  padding: {
    paddingLeft: authContainer.paddingLg.left,
    paddingRight: authContainer.paddingLg.right,

    [theme.breakpoints.down("md")]: {
      paddingLeft: `${authContainer.paddingMd.left}`,
      paddingRight: `${authContainer.paddingSm.right}`,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: `${authContainer.paddingSm.left}`,
      paddingRight: `${authContainer.paddingSm.right}`,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: `${authContainer.paddingXs.left}`,
      paddingRight: `${authContainer.paddingXs.right}`,
    },
  },
  content: {
    margin: "auto 0",
  },
  title: {
    maxWidth: "712px",
    fontStyle: "normal",
    fontWeight: "100",
    fontSize: "60px",
    lineHeight: "88px",
    textTransform: "uppercase",

    [theme.breakpoints.down("xs")]: {
      fontSize: "calc(60px - 20px)",
      lineHeight: "calc(88px - 20px)",
    },
  },
  subtitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "47px",
    textTransform: "uppercase",

    [theme.breakpoints.down("xs")]: {
      fontSize: "calc(32px - 10px)",
      lineHeight: "calc(47px - 10px)",
    },
  },
});

export default entryStyle;
