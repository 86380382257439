import { duskyColor } from "assets/jss/material-dashboard-pro-react";
import { orangePeelColor } from "assets/jss/material-dashboard-pro-react";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/utils/buttonStyle";

const feedbackStyle = (theme) => ({
  ...buttonStyle,
  container: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 -16px -16px -16px", // Fix Grid from adding -16px to top
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
  },
  radio: {
    "&$checked": {
      color: orangePeelColor[0],
    },
  },
  checked: {
    //
  },
  starRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  star: {
    fontSize: "40px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
    },
  },
  starError: {
    color: "red",
  },
  starFilled: {
    color: orangePeelColor[0],
  },
  feedbackTextBox: {
    width: "100%",
    maxWidth: "550px",
  },
  textboxRoot: {
    "& fieldset": {
      border: `1px solid ${duskyColor[0]}`,
    },
  },
  headerContent: {
    paddingBottom: "30px",
  },
  textboxLabel: {
    paddingBottom: "15px",
  },
  gutterBottom: {
    marginBottom: "20px",
  },
});

export default feedbackStyle;
