import {
  atlanticColor,
  chillColor,
} from "assets/jss/material-dashboard-pro-react.js";

const buttonStyle = {
  button: {
    fontWeight: "600",
    fontSize: "20px",
    padding: "1px 20px 6px",
    height: "36px",
    borderRadius: "8px",
    margin: "15px 0px",
  },
  buttonLarge: {
    fontWeight: "600",
    padding: "1px 20px 6px",
    //width: "172px",
    //height: "36px",
    width: "190px",
    height: "50px",
    borderRadius: "8px",
    margin: "10px 0px",
    fontSize: "20px",
  },
  atlantic: {
    color: chillColor[0],
    background: atlanticColor[0],
    "&:hover": {
      background: chillColor[0],
      color: atlanticColor[0],
      border: `2px solid ${atlanticColor[0]}`,
    },
    "&:active": {
      background: chillColor[0],
      color: atlanticColor[0],
      border: `2px solid ${atlanticColor[0]}`,
    },
    "&:focus": {
      background: chillColor[0],
      color: atlanticColor[0],
      border: `2px solid ${atlanticColor[0]}`,
    },
  },
  chill: {
    color: atlanticColor[0],
    background: chillColor[0],
    "&:hover": {
      background: chillColor[0],
      color: atlanticColor[0],
    },
    "&:active": {
      background: chillColor[0],
      color: atlanticColor[0],
    },
    "&:focus": {
      background: chillColor[0],
      color: atlanticColor[0],
    },
  },
};

export default buttonStyle;
