import React from "react";

import { auth } from "services/firebase";

const UserContext = React.createContext({});

const UserProvider = (props) => {
  const { children } = props;

  const [user, setUser] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [userData, setUserData] = React.useState(null);

  // Load user from firebase authenticaton
  React.useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged(async (userAuth) => {
      setUser(userAuth);
      setLoading(false);
    });
  }, []);

  return (
    <UserContext.Provider value={[user, loading, userData, setUserData]}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
