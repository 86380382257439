const avatarStyle = (theme) => ({
  progressContainer: {
    marginTop: "10px",
  },
  avatarSmall: {
    width: "35px",
    height: "35px",
  },
});

export default avatarStyle;
