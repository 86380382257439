import React from "react";

import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
} from "@material-ui/icons";

import {
  Button,
  IconButton,
  Typography,
  FormControl,
  TextField,
  makeStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";

// @material-ui/icons
import FeedbackIcon from "@material-ui/icons/Feedback";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import CustomAlert from "components/Alert/CustomAlert";
import styles from "assets/jss/material-dashboard-pro-react/views/feedbackStyle.js";
import { sendDeveloperFeedback } from "database/Firestore";
import { UserContext } from "services/auth";

const useStyles = makeStyles(styles);

export default function Feedback(props) {
  const { setMasterAlert } = props;
  const classes = useStyles();
  const [user] = React.useContext(UserContext);
  const [stars, setStars] = React.useState(Array(5).fill(false));
  const [starsError, setStarsError] = React.useState(false);
  const [feedbackText, setFeedbackText] = React.useState("");
  const [feedbackError, setFeedbackError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [failure, setFailure] = React.useState(false);

  const handleStar = (idx) => {
    let _stars = stars.map((star, _idx) => {
      return _idx > idx ? false : true;
    });
    setStars(_stars);
    setStarsError(false);
  };

  const handleFeedbackText = (e) => {
    setFeedbackText(e.target.value);
    setFeedbackError(false);
  };

  const onSubmit = () => {
    let validated = true;
    if (feedbackText === "") {
      setFeedbackError(true);
      validated = false;
    }
    if (stars.filter((obj) => obj === true).length === 0) {
      setStarsError(true);
      validated = false;
    }
    if (validated) {
      sendEmail();
    }
  };

  const sendEmail = async () => {
    setLoading(true);

    const body = `<ul>
        <li>
          <strong>Stars:</strong> ${stars.filter((filled) => filled).length}/${
      stars.length
    }
        </li>
        <li>
          <strong>Feedback:</strong> ${feedbackText}
        </li>
      </ul>`;

    const success = await sendDeveloperFeedback({ user: user, body: body });

    if (success) {
      setMasterAlert(
        <CustomAlert
          title="Feedback received!"
          onConfirm={() => setMasterAlert(null)}
          onCancel={() => setMasterAlert(null)}
          confirmBtnText={"Done"}
          success
        ></CustomAlert>
      );
      setFailure(false);
    } else {
      console.log("response did not return 200");
      setFailure(true);
    }

    setLoading(false);
  };

  return (
    <Grid container item spacing={4} className={classes.container}>
      <Grid item xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="orangePeel" icon>
            <CardIcon color="orangePeel">
              <FeedbackIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Feedback - <small>What can we improve on?</small>
            </h4>
          </CardHeader>
          <CardBody>
            <div className={classes.form}>
              <Typography>Rating:</Typography>
              <div className={classes.gutterBottom}>
                {stars.map((filled, idx) => {
                  let Icon = filled ? StarIcon : StarBorderIcon;
                  let _classes = classes.star;
                  if (filled) {
                    _classes += " " + classes.starFilled;
                  }
                  if (starsError) {
                    _classes += " " + classes.starError;
                  }

                  return (
                    <IconButton
                      onClick={() => handleStar(idx)}
                      key={idx}
                      classes={{ root: classes.starRoot }}
                    >
                      <Icon className={_classes} />
                    </IconButton>
                  );
                })}
              </div>
              <Typography gutterBottom className={classes.textboxLabel}>
                Tell us your thoughts below:
              </Typography>
              <FormControl
                className={classes.feedbackTextBox + " " + classes.gutterBottom}
              >
                <TextField
                  onChange={handleFeedbackText}
                  error={feedbackError}
                  helperText={feedbackError ? "Please add some feedback." : ""}
                  id="outlined-multiline-static"
                  multiline
                  rows={8}
                  variant="outlined"
                  classes={{ root: classes.textboxRoot }}
                />
              </FormControl>
              <Button color="secondary" onClick={onSubmit}>
                {loading ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={20}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
              {failure && (
                <Typography color="error">
                  Sorry, and issue occured.
                  <br />
                  If the issue persists please contact
                  alex.cregten@tahiteams.com.
                </Typography>
              )}
            </div>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
}
