import React from "react";

import CookieConsent from "react-cookie-consent";
import { isMobile } from "react-device-detect";

export default function Consent(props) {
  const { setConsented } = props;

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      enableDeclineButton
      cookieName="cookie-consented"
      style={{ background: "#2B373B", zIndex: "9999" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      declineButtonStyle={{ background: "transparent" }}
      onAccept={() => setConsented(true)}
      flipButtons={!isMobile}
    >
      We use cookies and Google Analytics to provide you with the best
      experience we can. We do not sell your data or provide it to any other
      third parties.
    </CookieConsent>
  );
}
