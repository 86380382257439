import { authContainer } from "assets/jss/material-dashboard-pro-react";

const bannerStyle = (theme) => ({
  frame: {
    paddingRight: authContainer.paddingLg.right,
    [theme.breakpoints.down("md")]: {
      paddingRight: `${authContainer.paddingMd.right}`,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: `${authContainer.paddingSm.right}`,
      paddingLeft: `${authContainer.paddingSm.left}`,
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: `${authContainer.paddingXs.right}`,
      paddingLeft: `${authContainer.paddingXs.left}`,
      paddingTop: "10px",
    },
    flex: 1,
  },
  row: {
    float: "right",

    marginRight: "110px",
    [theme.breakpoints.down("md")]: {
      marginRight: "55px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },

    display: "flex",
    flexDirection: "column",

    width: "190px",
  },
  heading: {
    width: "100%",
    justifyContent: "center",

    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "20px",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",

    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  buttonContainer: {
    //width: "172px",
    //width: "190",
    textAlign: "center",
  },
  subtextContainer: {
    textAlign: "center",
  },
  buttonFiller: {
    height: "66px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export default bannerStyle;
