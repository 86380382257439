import React from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/utils/customAlertStyle.js";
import { orangePeelColor } from "assets/jss/material-dashboard-pro-react";
const useStyles = makeStyles(styles);

export default function CustomAlert(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <SweetAlert
      {...props}
      style={{
        display: "block",
        marginTop: "-100px",
        border: `2px solid ${orangePeelColor[0]}`,
      }}
      confirmBtnCssClass={
        classes.button + " " + classes.orangePeel + " " + classes.antiBlurText
      }
      cancelBtnCssClass={
        classes.button + " " + classes.transparent + " " + classes.antiBlurText
      }
    >
      {children}
    </SweetAlert>
  );
}
