import React from "react";

import { green, red } from "@material-ui/core/colors";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

function createData(title, freeValue, proValue) {
  return { title, freeValue, proValue };
}

const rows = [
  //createData("Local data hosting", "-", "y"),
  //createData("Realtime collaboration", "y", "n"),
  //createData("Save data to the cloud", "y", "n"),
  createData("Rank and manage stakeholders", "y", "y"),

  createData("Power interest grid", "y", "y"),
  createData("Salience model", "y", "y"),
  createData("Action plan", "y", "y"),

  createData("Import stakeholders", "y", "y"),
  createData("Export models to PDF", "y", "y"),

  createData("Storage", "Cloud", "Local"),
  createData("Share projects with anyone", "y", "n"),
];

const value = (v) => {
  switch (v) {
    case "n":
      return <ClearIcon style={{ color: red[500] }} />;
    case "y":
      return <CheckIcon style={{ color: green[500] }} />;
    default:
      return v;
  }
};

export { rows, value };
