import React from "react";

import Feedback from "views/Admin/Feedback.js";
import Account from "views/Admin/Account.js";

import SimpleEntry from "views/Auth/SimpleEntry.js";
import SignUp from "views/Customer/SignUp.js";
import Contact from "views/Customer/Contact.js";

import Pricing from "views/Pricing/Pricing.js";
import Blog from "views/Blog/Blog";

import FeedbackIcon from "@material-ui/icons/Feedback";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const PrivacyPolicy = React.lazy(() => import("views/Legal/PrivacyPolicy"));
const CookiePolicy = React.lazy(() => import("views/Legal/CookiePolicy"));
const Project = React.lazy(() => import("views/Admin/Project"));

const Blog1 = React.lazy(() => import("views/Blog/Blog1"));
const Blog2 = React.lazy(() => import("views/Blog/Blog2"));
const Blog3 = React.lazy(() => import("views/Blog/Blog3"));

var dashRoutes = [
  {
    path: "/project",
    name: "",
    component: Project,
    layout: "/admin",
    project: true,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    layout: "/admin",
    adminItem: true,
    icon: AccountCircleIcon,
  },
  {
    path: "/feedback",
    name: "Developer feedback",
    component: Feedback,
    layout: "/admin",
    adminItem: true,
    icon: FeedbackIcon,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    layout: "/legal",
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: CookiePolicy,
    layout: "/legal",
  },
  {
    path: "/a-guide-to-stakeholder-mapping-tools",
    name: "A guide to stakeholder mapping tools",
    component: Blog3,
    layout: "/journal",
    data: {
      imageSrc: require("assets/img/blogs/blog3/landing.jpg"),
    },
  },
  {
    path: "/how-to-treat-your-career-like-a-project",
    name: "How to treat your career like a project",
    component: Blog1,
    layout: "/journal",
    data: {
      imageSrc: require("assets/img/blogs/blog1/landing-min.jpg"),
    },
  },
  {
    path: "/startups-and-their-need-for-stakeholder-analysis",
    name: "Startups and their need for Stakeholder Analysis",
    component: Blog2,
    layout: "/journal",
    data: {
      imageSrc: require("assets/img/blogs/blog2/HandsLandingImage-min.jpeg"),
    },
  },
  {
    path: "/",
    name: "Journal",
    component: Blog,
    layout: "/journal",
  },
  {
    path: "/",
    name: "Entry",
    component: SimpleEntry,
    layout: "/auth",
  },
  {
    path: "/",
    name: "Pricing",
    component: Pricing,
    layout: "/pricing",
  },
  {
    path: "/sign-up",
    name: "Pricing",
    component: SignUp,
    layout: "/customer",
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    layout: "/customer",
  },
];

export { dashRoutes };
