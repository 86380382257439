import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import AuthNavbar from "components/Navigation/Navbars/Auth/AuthNavbar";

import { dashRoutes as routes } from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/customerStyle";
import Footer from "components/Sales/Footer";
//import Banner from "components/Sales/Banner";

const useStyles = makeStyles(styles);

export default function Customer(props) {
  const classes = useStyles();
  const { setSnackbar, ...rest } = props;

  const getRoutes = (routes) => {
    return routes
      .filter((route) => route.layout === "/customer")
      .map((prop, key) => {
        if (prop.collapse) {
          return getRoutes(prop.views);
        }

        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(props) => (
              <prop.component
                key={prop.layout + prop.path}
                {...props}
                setSnackbar={setSnackbar}
              />
            )}
          />
        );
      });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.fullPage}>
        <AuthNavbar {...rest} shrinkOnValue={275} />

        <div className={classes.content}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/" to="/" />
          </Switch>
          <Footer />
        </div>
      </div>
    </div>
  );
}
