import React from "react";

import { makeStyles } from "@material-ui/core";

import Banner from "components/Sales/Banner";

import styles from "assets/jss/material-dashboard-pro-react/views/entryStyle.js";
import ContactForm from "components/Sales/ContactForm";
//import Carousel from "components/Sales/Carousel";

const useStyles = makeStyles(styles);

export default function SimpleEntry(props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Banner />
      {/*
      <Carousel />
      */}
      <div className={classes.simpleEntryContactForm + " " + classes.padding}>
        <ContactForm {...props} />
      </div>
    </div>
  );
}
