const blogStyle = (theme) => ({
  content: {
    width: "100%",
  },
  cardAction: {
    display: "block",
    textAlign: "center",
  },
  cardActionRoot: {
    width: "100%",
    padding: "5px",
  },
  blogContent: {
    fontSize: "21px",
    color: "black",
    lineHeight: "1.58",
    letterSpacing: "-.003em",
    fontFamily: "Arial",
    maxWidth: "100%",

    //padding: "0 250px 150px 250px",
    padding: "0 150px 150px 150px",
    //[theme.breakpoints.down("md")]: {
    //  padding: "0 150px 150px 150px",
    //},
    [theme.breakpoints.down("sm")]: {
      padding: "0 50px 150px 50px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  subheading: {
    paddingBottom: "30px",
    paddingTop: "30px",
  },
  listitem: {
    margin: "0 0 20px 0",
  },
  scrollcontainer: {
    paddingBottom: "30px",
  },
  quote: {
    textAlign: "center",
    paddingBottom: "20px",
  },
  gutterBottom: {
    paddingBottom: "50px",
  },
  gutterTop: {
    paddingTop: "50px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 0",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  dialogImage: {
    //width: "100%",
    //height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  card: {
    maxWidth: "500px",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  cardContent: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
});

export default blogStyle;
