import buttonStyle from "assets/jss/material-dashboard-pro-react/components/utils/buttonStyle";

const contactStyle = (theme) => ({
  ...buttonStyle,
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default contactStyle;
