import React from "react";

import Email from "components/Email/Email.js";

import { makeStyles, Typography } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/components/sales/contactFormStyle.js";

const useStyles = makeStyles(styles);

export default function ContactForm(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography className={classes.subheader}>
          Understand who has the power and interest to make or break your
          projects, and act where it really matters. Join our email list to hear
          about new features.
        </Typography>
        <Typography className={classes.header}>BE THE FIRST TO KNOW</Typography>
        <Email {...props} />
      </div>
    </div>
  );
}
