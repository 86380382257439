const pagesStyle = (theme) => ({
  //wrapper: {
  //  height: "auto",
  //  minHeight: "100vh",
  //  position: "relative",
  //  top: "0",
  //},

  wrapper: {
    overflow: "hidden",
  },

  fullPage: {
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",

    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
    //"&:before": {
    //  backgroundColor: "red",
    //},
  },
});

export default pagesStyle;
