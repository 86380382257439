import React from "react";

import * as EmailValidator from "email-validator";

import {
  TextField,
  InputAdornment,
  makeStyles,
  withStyles,
  CircularProgress,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";
import { ReactComponent as SubmitIcon } from "assets/icons/SubmitIcon.svg";

import styles from "assets/jss/material-dashboard-pro-react/components/utils/emailStyle.js";
import { atlanticColor } from "assets/jss/material-dashboard-pro-react";
import CustomAlert from "components/Alert/CustomAlert";
import { addEmailToContactForm } from "database/Firestore";

const useStyles = makeStyles(styles);

const ValidationTextField = withStyles({
  root: {
    boxSizing: "border-box",
    borderRadius: "7px",
    width: "100%",
    maxWidth: "300px",
    height: "37px",

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `2px solid ${atlanticColor[0]}`,
      },
      "&.Mui-focused fieldset": {
        border: `2px solid ${atlanticColor[0]}`,
      },
      "&.Mui-error fieldset": {
        border: `2px solid ${atlanticColor[0]}`,
      },
    },
  },
})(TextField);

export default function ContactForm(props) {
  const classes = useStyles();
  const { setMasterAlert } = props;
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(null);
  const [emailHelperText, setEmailHelperText] = React.useState("");

  const [symbol, setSymbol] = React.useState(<SubmitIcon />);

  const handleEmail = (e) => {
    if (e.keyCode === 13) {
      sendEmail();
    }
  };

  const sendEmail = async () => {
    //setLoading(true);

    setSymbol(
      <CircularProgress className={classes.circularProgress} size={20} />
    );

    let validated = EmailValidator.validate(email);
    if (validated) {
      setEmailError(false);

      try {
        await addEmailToContactForm(email);

        setEmailHelperText("Email address added to contact list!");
        setMasterAlert(
          <CustomAlert
            title="Email received!"
            onConfirm={() => setMasterAlert(null)}
            onCancel={() => setMasterAlert(null)}
            confirmBtnText={"Done"}
            success
          ></CustomAlert>
        );
      } catch (error) {
        // Something went wrong
        console.log(error);
      }
    } else {
      setEmailError(true);
      setEmailHelperText("Incorrect email");
    }
    setSymbol(<SubmitIcon />);
  };

  React.useEffect(() => {
    if (email === "") setEmailHelperText("");
  }, [email]);

  return (
    <ValidationTextField
      className={classes.textField}
      id="outlined-basic"
      label=""
      variant="outlined"
      placeholder="Email"
      error={emailError}
      helperText={emailHelperText}
      onKeyDown={handleEmail}
      onChange={(e) => setEmail(e.target.value)}
      InputProps={{
        classes: {
          root: classes.inputRoot,
        },
        endAdornment: (
          <InputAdornment position="end">
            <Button
              className={classes.atlantic + " " + classes.submitBtn}
              onClick={sendEmail}
            >
              {symbol}
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
}
