import React from "react";

import cx from "classnames";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import { IconButton, Button, SwipeableDrawer } from "@material-ui/core";

import Heading from "components/Navigation/Navbars/Auth/Heading";
import { IsMedium } from "components/Responsive/Responsive.js";

import styles from "assets/jss/material-dashboard-pro-react/components/navbar/auth/authNavbarStyle.js";
import CustomAvatar from "components/User/Avatar";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const isMedium = IsMedium();
  const classes = useStyles();
  const { shrinkOnValue } = props;
  const [hasScrolledDown, setHasScrolledDown] = React.useState(false);
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);

  React.useEffect(() => {
    function resizeHeaderOnScroll() {
      const distanceY =
          window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = shrinkOnValue;

      if (distanceY > shrinkOn) {
        setHasScrolledDown(true);
      } else {
        setHasScrolledDown(false);
      }
    }

    window.addEventListener("scroll", resizeHeaderOnScroll);

    return () => {
      window.removeEventListener("scroll", resizeHeaderOnScroll);
    };
  }, [shrinkOnValue]);

  // verifies if routeName is the one active (in browser input)
  //const activeRoute = (routeName) => {
  //  return window.location.href.indexOf(routeName) > -1 ? true : false;
  //};

  const handleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  var navList = (
    <>
      <ListItem className={classes.listItem}>
        <Button
          classes={{ root: classes.listButton }}
          component={Link}
          to="/"
          color="secondary"
          size="large"
        >
          Home
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          classes={{ root: classes.listButton }}
          component={Link}
          to="/pricing"
          color="secondary"
        >
          Pricing
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          classes={{ root: classes.listButton }}
          component={Link}
          to="/journal"
          color="secondary"
        >
          Journal
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          classes={{ root: classes.listButton }}
          component={Link}
          to="/admin/project"
          color="secondary"
        >
          Go to dashboard
        </Button>
      </ListItem>
    </>
  );

  const list =
    classes.list +
    " " +
    cx({
      [classes.alignItems]: hasScrolledDown,
    });

  if (isMedium) {
    return (
      <AppBar
        position="static"
        className={classes.appBar + " " + classes.moveableAppBar}
      >
        <Toolbar className={classes.containerSmaller}>
          <Heading isMobile={isMedium} />

          <IconButton className={classes.menuBtn} onClick={handleMobileMenu}>
            <Menu className={classes.menuIcon} />
          </IconButton>

          <SwipeableDrawer
            anchor="top"
            open={openMobileMenu}
            onClose={handleMobileMenu}
          >
            <div
              className={classes.mobileDrawer}
              onClick={handleMobileMenu}
              onKeyDown={handleMobileMenu}
            >
              <div className={classes.mobileDrawerContent}>{navList}</div>
            </div>
          </SwipeableDrawer>

          <CustomAvatar buttonClasses={classes.listButton} />
        </Toolbar>
      </AppBar>
    );
  } else {
    return (
      <AppBar
        position="static"
        className={classes.appBar + " " + classes.moveableAppBar}
      >
        <Toolbar className={classes.containerSmaller}>
          <Heading hasScrolledDown={hasScrolledDown} />

          <List className={list}>
            {navList}
            <ListItem className={classes.listItem}>
              <CustomAvatar buttonClasses={classes.listButton} />
            </ListItem>
          </List>
        </Toolbar>
      </AppBar>
    );
  }
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  shrinkOnValue: PropTypes.number,
};
