import React from "react";

import { Helmet } from "react-helmet";

import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { dashRoutes as routes } from "routes.js";

import AuthNavbar from "components/Navigation/Navbars/Auth/AuthNavbar";
import Footer from "components/Sales/Footer";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const classes = useStyles();
  const { setMasterAlert, ...rest } = props;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        if (prop.path === "/") {
          return (
            <Route
              path={prop.path}
              key={key}
              render={(props) => (
                <prop.component {...props} setMasterAlert={setMasterAlert} />
              )}
            />
          );
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Stakeholder Mapping and Analysis</title>
        <meta
          name="description"
          content="Stakeholders.app | Stakeholder Mapping and Analysis Platform"
        />
      </Helmet>
      <div className={classes.fullPage}>
        <AuthNavbar {...rest} shrinkOnValue={500} />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/" to="/" />
        </Switch>
        <Footer />
      </div>
    </div>
  );
}
