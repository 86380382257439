import React from "react";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import styles from "assets/jss/material-dashboard-pro-react/components/sales/footerStyle";

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.container /* + " " + classes.padding*/}>
      <div className={classes.textLinks}>
        <div className={classes.textLink}>
          <Link to="/legal/privacy-policy">Privacy policy</Link>
        </div>

        <div className={classes.textLink}>
          <Link to="/legal/cookie-policy">Cookie policy</Link>
        </div>
      </div>

      <div>
        <div className={classes.links}>
          <a
            href="https://twitter.com/Alex53106399"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>

          <a
            href="https://www.linkedin.com/company/stakeholders-app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
        </div>
      </div>
    </div>
  );

  /*
  return (
    <div className={classes.container + " " + classes.padding}>
      <div className={classes.content}>
        <div className={classes.links}>
          <a
            href="https://twitter.com/Alex53106399"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>

          <a
            href="https://www.linkedin.com/company/stakeholders-app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
        </div>
      </div>
    </div>
  );
  */
}
