import React from "react";

import { makeStyles /*, Typography*/ } from "@material-ui/core";

//import Card from "@material-ui/core/Card";
//import CardActions from "@material-ui/core/CardActions";
//import CardContent from "@material-ui/core/CardContent";

//import { UserContext } from "services/auth";

import styles from "assets/jss/material-dashboard-pro-react/views/contactStyle.js";

const useStyles = makeStyles(styles);

export default function SignUp() {
  const classes = useStyles();
  //const [user] = React.useContext(UserContext);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {/*
        <Card>
          <CardContent>
            <Typography>Email: alex.cregten@tahiteams.com</Typography>
          </CardContent>
          <CardActions
            classes={{ root: classes.cardActionsRoot }}
          ></CardActions>
        </Card>
        */}
      </div>
    </div>
  );
}
