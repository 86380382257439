import React from "react";

import "assets/css/custom.css";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import "react-perfect-scrollbar/dist/css/styles.css";

import ReactDOM from "react-dom";

import { CookiesProvider } from "react-cookie";

import { ThemeProvider } from "@material-ui/core/styles";

import { createBrowserHistory } from "history";

import { Router, Route, Switch, Redirect } from "react-router-dom";

import { theme } from "themeContext.js";
import RouteChangeTracker from "analytics/RouteChangeTracker";

import AuthLayout from "layouts/Auth.js";
import BlogLayout from "layouts/Blog.js";
import PricingLayout from "layouts/Pricing.js";
import CustomerLayout from "layouts/Customer.js";
import LoadingLogo from "components/Loading/LoadingLogo";
import ErrorBoundary from "ErrorBoundary";

import { UserProvider } from "services/auth";

import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

const AdminLayout = React.lazy(() => import("layouts/Admin.js"));
const LegalLayout = React.lazy(() => import("layouts/Legal.js"));

const hist = createBrowserHistory();

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
} else {
  // prod code
  if (!window.console) window.console = {};
  var methods = ["log", "debug", "warn", "info"];
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {};
  }
}

const App = () => {
  const [masterAlert, setMasterAlert] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState({ show: false, message: "" });

  // Handle exiting checkout without subscribing
  React.useEffect(() => {
    const query = new URLSearchParams(hist.location.search);

    const checkout = query.get("checkout");
    if (checkout === "success") {
      setSnackbar({
        show: true,
        message:
          "Subscribed!  It might take a moment for your account changes to take place.",
      });
    }
  }, []);

  const closeSnakbar = () => {
    setSnackbar({ show: false, message: "" });
  };

  const TransitionDown = (props) => {
    return <Slide {...props} direction="down" />;
  };

  return (
    <ThemeProvider theme={theme}>
      {masterAlert}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar.show}
        onClose={closeSnakbar}
        message={snackbar.message}
        TransitionComponent={TransitionDown}
        style={{ pointerEvents: "none" }}
      />

      <Router history={hist}>
        <UserProvider>
          <RouteChangeTracker />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <AuthLayout setMasterAlert={setMasterAlert} />}
            />
            <Route
              path="/admin"
              render={(props) => (
                <React.Suspense fallback={<LoadingLogo />}>
                  <AdminLayout
                    history={hist}
                    setMasterAlert={setMasterAlert}
                    setSnackbar={setSnackbar}
                  />
                </React.Suspense>
              )}
            />
            <Route
              path="/journal"
              render={(props) => (
                <React.Suspense fallback={<LoadingLogo />}>
                  <BlogLayout />
                </React.Suspense>
              )}
            />
            <Route
              path="/pricing"
              render={(props) => (
                <React.Suspense fallback={<LoadingLogo />}>
                  <PricingLayout />
                </React.Suspense>
              )}
            />
            <Route
              path="/legal"
              render={(props) => (
                <React.Suspense fallback={<LoadingLogo />}>
                  <LegalLayout />
                </React.Suspense>
              )}
            />
            <Route
              path="/auth"
              render={(props) => (
                <React.Suspense fallback={<LoadingLogo />}>
                  <AuthLayout />
                </React.Suspense>
              )}
            />

            <Route
              path="/customer"
              render={(props) => (
                <React.Suspense fallback={<LoadingLogo />}>
                  <CustomerLayout setSnackbar={setSnackbar} />
                </React.Suspense>
              )}
            />
            <Redirect from="/" to="/" />
          </Switch>
        </UserProvider>
      </Router>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
