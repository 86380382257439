import {
  createProject,
  duplicateProjectData,
  createUser,
} from "database/Factory";
import { maxStakeholderCount } from "variables/restrictions";

const addUserDataToLocalStorage = () => {
  console.log("addUserData ...");

  const userData = createUser({ free: true });
  window.localStorage.setItem("userData", JSON.stringify(userData));
  return userData;
};

const getUserDataFromLocalStorage = ({ observer }) => {
  function action() {
    console.log("getUserDataFromLocalStorage ...");
    const userData = window.localStorage.getItem("userData");
    observer(JSON.parse(userData));
  }

  if (observer) {
    action();
    document.addEventListener("storage-userData", action);

    return () => {
      console.log("unsubscribing from storage-userData");
      document.removeEventListener("storage-userData", action);
    };
  } else {
    console.log("getUserDataFromLocalStorage ...");
    return JSON.parse(window.localStorage.getItem("userData"));
  }
};

const getAllProjectsFromLocalStorage = ({ observer }) => {
  function action() {
    console.log("getAllProjectsFromLocalStorage ...");
    let values = [];
    let keys = Object.keys(localStorage);
    let i = keys.length;
    while (i--) {
      let project = localStorage.getItem(keys[i]);
      try {
        project = JSON.parse(project);
      } catch {}
      if (project.id) {
        values.push(project);
      }
    }

    if (observer) {
      observer({ projects: values });
    } else {
      return values;
    }
  }

  if (observer) {
    action();
    document.addEventListener("storage-allProjects", action);

    return () => {
      console.log("unsubscribing from storage-allProjects");
      document.removeEventListener("storage-allProjects", action);
    };
  } else {
    return action();
  }
};

const getStakeholdersFromLocalStorage = ({ projectId, observer }) => {
  console.log("getStakeholdersFromLocalStorage ...");

  const action = () => {
    let stakeholders = window.localStorage.getItem(`stakeholders-${projectId}`);

    let arrayStakeholders = JSON.parse(stakeholders);
    if (!arrayStakeholders) arrayStakeholders = [];

    if (observer) {
      observer({ stakeholders: arrayStakeholders });
    } else {
      return arrayStakeholders;
    }
  };

  if (observer) {
    action();
    document.addEventListener("storage-stakeholders", action);
    return () => {
      console.log("unsubscribing from storage-stakeholders");
      document.removeEventListener("storage-stakeholders", action);
    };
  } else {
    return action();
  }
};

const updateExistingUserDataInLocalStorage = (userData, doNotDispatch) => {
  console.log("updateExistingUserDataInLocalStorage ...");

  window.localStorage.setItem("userData", JSON.stringify(userData));
  if (!doNotDispatch) {
    document.dispatchEvent(new Event("storage-userData"));
  }
};

const deleteSharedProjectLinkInLocalStorage = ({
  userLinkId,
  projectLinkId,
  userData,
}) => {
  console.log("deleteSharedProjectLinkInLocalStorage ...");

  const sharedProjectLinks = userData.projectsComponent.projectLinks;
  const projectLinkIds = sharedProjectLinks[userLinkId].filter(
    (projectId) => projectId !== projectLinkId
  );
  sharedProjectLinks[userLinkId] = projectLinkIds;
  userData.projectsComponent.projectLinks = sharedProjectLinks;

  updateExistingUserDataInLocalStorage(userData);
};

const getSharedProjectLinksFromLocalStorage = ({ callback }) => {
  console.log("getSharedProjectLinksFromLocalStorage ...");

  const userData = getUserDataFromLocalStorage({});
  const sharedProjectLinks = userData.projectsComponent.projectLinks;

  if (!sharedProjectLinks) callback({ links: {} });
  else callback({ links: sharedProjectLinks });
  return null;
};

const addSharedProjectLinkToLocalStorage = ({ userLinkId, projectLinkId }) => {
  console.log("addSharedProjectLinkToLocalStorage ...");

  getSharedProjectLinksFromLocalStorage({
    callback: (data) => {
      let projectLinks = data.links;

      if (userLinkId in projectLinks) {
        let links = new Set(projectLinks[userLinkId]);
        links.add(projectLinkId);
        projectLinks[userLinkId] = [...links];
      } else {
        projectLinks[userLinkId] = [projectLinkId];
      }

      const userData = getUserDataFromLocalStorage({});
      userData.projectsComponent.projectLinks = projectLinks;
      updateExistingUserDataInLocalStorage(userData);
    },
  });
};

const addProjectIdOrderingToLocalStorage = ({ projectId, doNotDispatch }) => {
  console.log("addProjectIdOrderingToLocalStorage ...");

  const userData = getUserDataFromLocalStorage({});
  const projectIds = userData.projectsComponent.ordering.projectIds;
  userData.projectsComponent.ordering.projectIds = [...projectIds, projectId];
  updateExistingUserDataInLocalStorage(userData, doNotDispatch);
};

const setProjectIdOrderingToLocalStorage = ({ projectIds }) => {
  console.log("setProjectIdOrderingToLocalStorage ...");

  const userData = getUserDataFromLocalStorage({});
  userData.projectsComponent.ordering.projectIds = projectIds;
  updateExistingUserDataInLocalStorage(userData);
};

const duplicateProjectFromLocalStorage = ({ project }) => {
  console.log("duplicateProjectFromLocalStorage ...");

  var stakeholders = getStakeholdersFromLocalStorage({ projectId: project.id });

  let [dupProject, dupStakeholders] = duplicateProjectData({
    project: project,
    stakeholders: stakeholders,
    userId: null,
  });

  addProjectToLocalStorage({ newProject: dupProject });
  setStakeholdersInLocalStorage({
    projectId: dupProject.id,
    stakeholders: dupStakeholders,
    doNotDispatch: true,
  });

  return dupProject;
};

const deleteProjectFromLocalStorage = ({ projectId, projectIds }) => {
  console.log("deleteProjectFromLocalStorage ...");

  const userData = getUserDataFromLocalStorage({});
  userData.projectsComponent.ordering.projectIds = projectIds;
  updateExistingUserDataInLocalStorage(userData);

  window.localStorage.removeItem(projectId);
  window.localStorage.removeItem(`stakeholders-${projectId}`);

  document.dispatchEvent(new Event("storage-allProjects"));
};

const updateExistingProjectInLocalStorage = ({
  projectId,
  project,
  silent,
  doNotDispatch,
}) => {
  console.log("updateExistingProjectInLocalStorage ...");

  if (!silent) {
    project.updated = Date.now();
  }
  window.localStorage.setItem(projectId, JSON.stringify(project));

  if (!doNotDispatch) {
    document.dispatchEvent(new Event("storage-allProjects"));
  }
};

const updateExistingStakeholderInLocalStorage = ({
  projectId,
  updatedStakeholder,
}) => {
  console.log("updateExistingStakeholderInLocalStorage ...");

  var stakeholders = getStakeholdersFromLocalStorage({ projectId: projectId });

  let updatedStakeholders = stakeholders.map((stakeholder) => {
    if (stakeholder.id === updatedStakeholder.id) {
      stakeholder = updatedStakeholder;
    }
    return stakeholder;
  });
  setStakeholdersInLocalStorage({
    projectId: projectId,
    stakeholders: updatedStakeholders,
    doNotDispatch: true,
  });
};

const setProjectsInLocalStorage = (projects) => {
  console.log("setProjectsInLocalStorage ...");

  projects.forEach((project) => {
    window.localStorage.setItem(project.id, JSON.stringify(project));
  });
};

const addProjectToLocalStorage = ({ newProject, doNotDispatch }) => {
  console.log("addProjectToLocalStorage ...");

  let project = createProject({ userId: null });
  if (newProject) {
    project = newProject;
  }
  window.localStorage.setItem(project.id, JSON.stringify(project));
  window.localStorage.setItem(`stakeholders-${project.id}`, JSON.stringify([]));

  if (!doNotDispatch) {
    document.dispatchEvent(new Event("storage-allProjects"));
  }

  addProjectIdOrderingToLocalStorage({
    projectId: project.id,
    doNotDispatch: doNotDispatch,
  });
  return project;
};

const updateStakeholderInLocalStorage = ({
  projectId,
  stakeholder,
  project,
  doNotDispatch,
}) => {
  var stakeholders = getStakeholdersFromLocalStorage({ projectId: projectId });

  if (stakeholders.length > maxStakeholderCount) {
    console.log("Too many stakeholders");
    return;
  }

  let index = stakeholders.findIndex((s) => s.id === stakeholder.id);
  if (index >= 0) {
    // update existing stakeholder
    stakeholders[index] = stakeholder;
  } else {
    // Add stakeholder if not exist
    stakeholders.push(stakeholder);
  }

  updateExistingProjectInLocalStorage({
    project: project,
    projectId: project.id,
    silent: false,
    doNotDispatch: doNotDispatch,
  });

  setStakeholdersInLocalStorage({
    projectId: projectId,
    stakeholders: stakeholders,
    doNotDispatch: doNotDispatch,
  });
};

const getProjectFromLocalStorage = (projectId) => {
  console.log("getProjectFromLocalStorage ...");

  let project = window.localStorage.getItem(projectId);
  return JSON.parse(project);
};

const setStakeholdersInLocalStorage = ({
  projectId,
  stakeholders,
  doNotDispatch,
}) => {
  console.log("setStakeholdersInLocalStorage ...");

  window.localStorage.setItem(
    `stakeholders-${projectId}`,
    JSON.stringify(stakeholders)
  );

  if (!doNotDispatch) {
    document.dispatchEvent(new Event("storage-stakeholders"));
  }
};

const deleteStakeholderInLocalStorage = (stakeholderId, project) => {
  console.log("deleteStakeholderInLocalStorage ...");

  let stakeholders = getStakeholdersFromLocalStorage({ projectId: project.id });

  stakeholders = stakeholders.filter(
    (stakeholder) => stakeholder.id !== stakeholderId
  );

  updateExistingProjectInLocalStorage({
    project: project,
    projectId: project.id,
    silent: false,
  });

  setStakeholdersInLocalStorage({
    projectId: project.id,
    stakeholders: stakeholders,
    doNotDispatch: true,
  });
};

const addManyStakeholdersToLocalStorage = ({ project, stakeholders }) => {
  console.log("addManyStakeholdersToLocalStorage ...");

  updateExistingProjectInLocalStorage({
    projectId: project.id,
    project: project,
  });
  setStakeholdersInLocalStorage({
    projectId: project.id,
    stakeholders: stakeholders,
  });
};

export {
  duplicateProjectFromLocalStorage,
  deleteProjectFromLocalStorage,
  updateExistingProjectInLocalStorage,
  setProjectsInLocalStorage,
  addProjectToLocalStorage,
  getAllProjectsFromLocalStorage,
  getProjectFromLocalStorage,
  getStakeholdersFromLocalStorage,
  setStakeholdersInLocalStorage,
  deleteStakeholderInLocalStorage,
  updateExistingStakeholderInLocalStorage,
  addSharedProjectLinkToLocalStorage,
  getSharedProjectLinksFromLocalStorage,
  deleteSharedProjectLinkInLocalStorage,
  updateStakeholderInLocalStorage,
  addProjectIdOrderingToLocalStorage,
  setProjectIdOrderingToLocalStorage,
  addUserDataToLocalStorage,
  getUserDataFromLocalStorage,
  addManyStakeholdersToLocalStorage,
};
