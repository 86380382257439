//import { atlanticColor } from "assets/jss/material-dashboard-pro-react";
import {
  almondColor,
  //authContainer,
} from "assets/jss/material-dashboard-pro-react";

const footerStyle = (theme) => ({
  //padding: {
  //  paddingLeft: `${authContainer.paddingLg.left}`,
  //  paddingRight: `${authContainer.paddingSm.right}`,
  //  [theme.breakpoints.down("md")]: {
  //    paddingLeft: `${authContainer.paddingMd.left}`,
  //    paddingRight: `${authContainer.paddingSm.right}`,
  //  },
  //  [theme.breakpoints.down("sm")]: {
  //    paddingLeft: `${authContainer.paddingSm.left}`,
  //    paddingRight: `${authContainer.paddingSm.right}`,
  //  },
  //  [theme.breakpoints.down("xs")]: {
  //    paddingLeft: `${authContainer.paddingXs.left}`,
  //    paddingRight: `${authContainer.paddingXs.right}`,
  //  },
  //},
  container: {
    zIndex: "999",
    position: "absolute",
    bottom: 0,
    width: "100vw",

    background: almondColor[0],
    height: "35px",
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      height: "70px",
    },

    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 50px",
  },
  //content: {
  //  display: "flex",
  //  alignItems: "center",
  //},

  links: {
    height: "26px",
    display: "flex",
    justifyContent: "space-between",
    //width: "120px",
    width: "70px",
  },
  link: {
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "22px",
  },
  textLinks: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  textLink: {
    marginRight: "20px",
  },
  //divider: {
  //  backgroundColor: atlanticColor[0], //"#04345C",
  //},
  //iconFontAwesome: {
  //  fontSize: "21px",
  //  padding: "2px",
  //},
});

export default footerStyle;
