const emailStyle = (theme) => ({
  container: {
    //
  },
  subheader: {
    fontStyle: "normal",
    fontWeight: "100",
    fontSize: "20px",
    lineHeight: "29px",

    marginBottom: "20px",
    maxWidth: "526px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      fontWeight: "300",
    },
  },
  header: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "35px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
});

export default emailStyle;
