import React from "react";

import { withRouter } from "react-router-dom";

import ReactGA from "react-ga";
import { useCookies } from "react-cookie";

import { tz } from "moment-timezone";

import CookieConsent from "./CookieConsent";

const RouteChangeTracker = ({ history }) => {
  const [cookies] = useCookies(["cookie-consented"]);
  const [consented, setConsented] = React.useState(!GDPR());

  React.useEffect(() => {
    //console.log(consented);

    if (cookies["cookie-consented"] === "false") {
      console.log("Cookie not consented");
      return;
    }

    if (cookies["cookie-consented"] === "true" || consented) {
      const TRACKING_ID = "UA-192604955-1";
      ReactGA.initialize(TRACKING_ID);

      const unregister = history.listen((location, action) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });

      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);

      return function cleanup() {
        //listening.unlisten();
        unregister();
      };
    } else {
      console.log("Cookie not consented");
    }

    // eslint-disable-next-line
  }, [cookies, consented]);

  if (GDPR()) {
    return <CookieConsent setConsented={setConsented} />;
  }

  return <></>;
};

const GDPR = () => {
  var _tz = tz.guess();
  switch (_tz) {
    case "America/Los_Angeles": // Should be opt-out, but in here because I'm lazy.
      return true;
    case "Europe/Vienna":
      return true;
    case "Europe/Brussels":
      return true;
    case "Europe/Sofia":
      return true;
    case "Europe/Zagreb":
      return true;
    case "Asia/Famagusta":
      return true;
    case "Asia/Nicosia":
      return true;
    case "Europe/Prague":
      return true;
    case "Europe/Copenhagen":
      return true;
    case "Europe/Tallinn":
      return true;
    case "Europe/Helsinki":
      return true;
    case "Europe/Paris":
      return true;
    case "Europe/Berlin":
      return true;
    case "Europe/Busingen":
      return true;
    case "Europe/Athens":
      return true;
    case "Europe/Budapest":
      return true;
    case "Europe/Dublin":
      return true;
    case "Europe/Rome":
      return true;
    case "Europe/Riga":
      return true;
    case "Europe/Vilnius":
      return true;
    case "Europe/Luxembourg":
      return true;
    case "Europe/Malta":
      return true;
    case "Europe/Amsterdam":
      return true;
    case "Europe/Warsaw":
      return true;
    case "Atlantic/Azores":
      return true;
    case "Atlantic/Madeira":
      return true;
    case "Europe/Lisbon":
      return true;
    case "Europe/Bucharest":
      return true;
    case "Europe/Bratislava":
      return true;
    case "Europe/Ljubljana":
      return true;
    case "Africa/Ceuta":
      return true;
    case "Atlantic/Canary":
      return true;
    case "Europe/Madrid":
      return true;
    case "Europe/Stockholm":
      return true;
    default:
      return false;
  }
};

export default withRouter(RouteChangeTracker);
