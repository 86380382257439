import {
  container,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  orangePeelColor,
  atlanticColor,
} from "assets/jss/material-dashboard-pro-react.js";

const pagesHeaderStyle = (theme) => ({
  appBar: {
    backgroundColor: orangePeelColor[0],
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    zIndex: "1029",
    border: "0",
    padding: "0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
  },
  moveableAppBar: {
    position: "fixed",
  },
  containerSmaller: {
    ...container,
    minHeight: "50px",
    display: "flex",
    justifyContent: "space-between",

    marginLeft: "unset",
    marginRight: "unset",
    width: "100% !important",
    alignItems: "center",
  },
  appResponsive: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  alignItems: {
    alignItems: "center",
  },
  list: {
    fontSize: "14px",
    margin: 0,
    listStyle: "none",
    padding: "0 30px 0 0",
    position: "absolute",
    right: 0,

    display: "flex",
    height: "100%",
  },
  listButton: {
    fontSize: "16px",
    textTransform: "unset",
    fontWeight: "400",
  },
  listItem: {
    //margin: "0 0 0 14px",
    //padding: "0",
    padding: "14px",
    position: "relative",
    display: "block",
    width: "auto",
    [theme.breakpoints.up("md")]: {
      margin: "0",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  menuBtn: {
    width: "60px",
    height: "60px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  menuIcon: {
    fill: atlanticColor[0],
  },
  mobileDrawer: {
    overflow: "hidden",
    background: orangePeelColor[0],
    display: "flex",
  },
  mobileDrawerContent: {
    margin: "auto",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
});

export default pagesHeaderStyle;
