import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import {
  atlanticColor,
  orangePeelColor,
} from "assets/jss/material-dashboard-pro-react";

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: orangePeelColor[0],
      },
      secondary: {
        main: atlanticColor[0],
      },
    },
    typography: {
      fontFamily: ['"Be Vietnam"', "sans-serif"],
      h6: {
        textTransform: "none",
      },
      subtitle1: {
        textTransform: "none",
      },
      subtitle2: {
        textTransform: "none",
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "14px",
        },
      },
      MuiInputBase: {
        root: {
          color: "inherit",
          "& fieldset": {
            borderColor: "transparent",
          },
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  })
);

export { theme };
