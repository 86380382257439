import React from "react";

import { ReactComponent as LogoIcon } from "assets/icons/LogoIcon.svg";
import { Typography } from "@material-ui/core";
import ReactGA from "react-ga";

const style = {
  center: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  logo: {
    width: "100px",
    height: "100px",
  },
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, info: "", error: "" };
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true, info, error });
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log(`Error: ${error}`);
      console.log(`ErrorInfo: ${JSON.stringify(info)}`);
    } else {
      // Error reporting tool
      console.log(`Error: ${error}`);
      ReactGA.exception({
        description: `Error: ${error}\n ErrorInfo: ${JSON.stringify(info)}`,
        fatal: true,
      });
    }
  }

  render() {
    return this.state.hasError ? (
      <div style={style.center}>
        <LogoIcon style={style.logo} />
        <Typography color="error">An error has occured.</Typography>
        <Typography>
          If this problem persists, please email alex.cregten@tahiteams.com for
          troubleshooting assistance.
        </Typography>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
