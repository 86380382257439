import {
  orangePeelColor,
  authContainer,
} from "assets/jss/material-dashboard-pro-react";

const container = { height: "65vh", top: "17vh" };

const bannerStyle = (theme) => ({
  wrapper: {
    zIndex: "-1",
    paddingTop: authContainer.paddingTop,
    height: `calc(${container.height})`,
    minHeight: "340px",
    background: orangePeelColor[0],

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  wrapperSmall: {
    height: "30vh !important",
    [theme.breakpoints.up("sm")]: {
      minHeight: "430px",
    },
  },
  container: {
    paddingLeft: `${authContainer.paddingLg.left}`,
    paddingRight: `${authContainer.paddingSm.right}`,
    [theme.breakpoints.down("md")]: {
      paddingLeft: `${authContainer.paddingMd.left}`,
      paddingRight: `${authContainer.paddingSm.right}`,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: `${authContainer.paddingSm.left}`,
      paddingRight: `${authContainer.paddingSm.right}`,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: `${authContainer.paddingXs.left}`,
      paddingRight: `${authContainer.paddingXs.right}`,
    },
  },

  heading: {
    maxWidth: "708px",
  },
  title: {
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "100",
    fontSize: "60px",
    lineHeight: "88px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      fontSize: "26px",
      lineHeight: "1.167",
      fontWeight: "300",
    },
  },
  mobileTitle: {
    textAlign: "center",
  },
  mobileWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: "50px",
  },
  subtitle: {
    //textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "47px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "calc(32px / 2)",
      lineHeight: "calc(47px / 2)",
      paddingBottom: "10px",
    },
  },
});

export default bannerStyle;
