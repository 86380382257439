import React from "react";

import { ReactComponent as LogoIcon } from "assets/icons/LogoIcon.svg";

import LinearProgress from "@material-ui/core/LinearProgress";

const style = {
  center: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  logo: {
    width: "100px",
    height: "100px",
  },
};

export default function SalienceModel() {
  return (
    <div style={style.center}>
      <div>
        <LogoIcon style={style.logo} />
        <LinearProgress />
      </div>
    </div>
  );
}
