import {
  chillColor,
  atlanticColor,
} from "assets/jss/material-dashboard-pro-react.js";

const authHeaderStyle = (theme) => ({
  container: {
    flex: 1,
    color: atlanticColor[0],
  },
  logoContainer: {
    //display: "block",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    marginTop: "0",
    padding: "0",
  },
  titleTypography: {
    textTransform: "uppercase",
    lineHeight: "51.14px",
    fontSize: "35px",
    fontWeight: "100",
    fontStyle: "normal",

    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      lineHeight: "40px",
    },
  },
  subtitleTypography: {
    width: "120px",
    lineHeight: "23.38px",
    fontSize: "16px",
    fontWeight: "300",

    [theme.breakpoints.down("xs")]: {
      fontSize: "calc(16px - 6px)",
      lineHeight: "calc(23.38px - 6px)",
      width: "80px",
    },
  },
  divider: {
    //position: "fixed",
    color: chillColor[0],
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:before,&:after": {
      content: `""`,
      borderBottom: "1px solid " + chillColor[0],
    },
    "&:before": {
      //
      marginLeft: "1.5em",
    },
    "&:after": {
      marginLeft: "1.5em",
      marginTop: "5px",
      width: "100vw",
    },
  },
});

export default authHeaderStyle;
